import React from "react"

const components = {
  KettleVolume: async () => (await import("./components/KettleVolume")).KettleVolume,
  PrimingSugar: async () => (await import("./components/PrimingSugar")).PrimingSugar,
  RefractometerConverter: async () => (await import("./components/RefractometerConverter")).RefractometerConverter,
  DilutionAndBoilOff: async () => (await import("./components/DilutionAndBoilOff")).DilutionAndBoilOff,
}

export async function renderAll() {
  const containers = document.querySelectorAll("[data-react]")

  if (containers.length === 0) {
    return
  }

  const ReactDOM = (await import("react-dom/client")).default

  await Promise.all(
    [...containers].map(async (container) => {
      const componentName = container.dataset.react
      const componentPropsString = container.dataset.props
      const componentLoader = components[componentName]

      if (componentLoader === undefined || componentLoader === null) {
        console.error(`Component not found: ${container.dataset.react}`, container)
        return
      }

      let props = {}
      if (componentPropsString) {
        try {
          props = JSON.parse(componentPropsString)
        } catch (error) {
          console.error(`Failed to parse props for component: ${componentName}`, error)
        }
      }

      const Component = await componentLoader()

      const root = ReactDOM.createRoot(container, {
        identifierPrefix: `${componentName}-`,
      })

      root.render(
        <React.StrictMode>
          <Component {...props} />
        </React.StrictMode>,
      )
    }),
  )
}
