import "$styles/index.scss"

import { renderAll } from "./react-components"

// Import all JavaScript & CSS files from src/_components
// import components from "$components/**/*.{js,jsx,js.rb,css}"

// Import all images from frontend/images
import images from "../images/**/*.{jpg,jpeg,png,svg,gif,ico,webp,jxl}"
Object.entries(images).forEach((image) => image)

window.addEventListener("DOMContentLoaded", async () => {
  await renderAll()
})
